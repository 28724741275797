import { LoadingButton } from '@mui/lab';
import { Button, Card, CardHeader, Grid, Link, ListItemText, Stack, TableCell, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { alpha } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DrawerCustom, ListItemCustom, MoreOptions, OptionType, StyledTableRow, TableCustom } from 'src/components';
import { paths } from 'src/config';
import { allChipColors, allChipVariants } from 'src/constants/constants';
import { marketplaces_table_head } from 'src/features/project-offering-details/mock-data';
import { useBoolean, usePopover, useRouter } from 'src/hooks';
import { getPartnersInvestmentActivityList, publishOffering } from 'src/store/partners-portal';
import { getEquityMonitoringData } from 'src/store/storage';
import { fDate, fNumberWithDefaultCurrency, fPercent, openInNewTabHandler } from 'src/utils';

import { FundingDataType, FundingType, MarketplaceOffer, MarketplaceProjectActivity } from '../../../../../types';
import { FundingActivity, FundingTotal } from '../../marketplace-details';
import { FundingTable } from './funding-table';

export interface OfferingProps {
  projects?: MarketplaceOffer[];
  chartData?: FundingDataType;
  funding?: FundingType;
  projectId?: string;
  marketplaces?: MarketplaceProjectActivity[];
  partnerId: string;
  leadId?: string;
}

export const Offering = (props: OfferingProps) => {
  const {
    projects = [],
    marketplaces = [],
    chartData,
    funding,
    projectId = '',
    partnerId = '',
    leadId = '',
  } = props;

  const [currentItem, setCurrentItem] = useState<MarketplaceOffer>();
  const [marketplaceFilter, setMarketplaceFilter] = useState<string>('All Marketplaces');

  const detailsDrawer = useBoolean();
  const loadDetails = useBoolean();
  const popover = usePopover();
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId) {
      dispatch(getEquityMonitoringData(projectId));
    }
  }, [dispatch, projectId]);

  const detailsOnClickHandler = (itemId: string) => async () => {
    loadDetails.onTrue();
    const response = await getPartnersInvestmentActivityList({
      listWithoutQuery: false,
      page: 1,
      limit: 100,
      status: 'All',
      projectId,
    }, partnerId);
    loadDetails.onFalse();
    const currentItem = projects.find(item => item._id === itemId);
    if (currentItem) {
      const updatedCurrentItem: MarketplaceOffer = {
        ...currentItem,
        investors: response?.data || [],
      };
      setCurrentItem(updatedCurrentItem);
      detailsDrawer.onTrue();
    }
  };

  const unlistClickHandler = useCallback(async () => {
    popover.onClose();
    detailsDrawer.onFalse();
    if (currentItem?._id && currentItem.project?._id) {
      await publishOffering(currentItem.project?._id, { isPublished: false }, partnerId, '', currentItem?._id);
      dispatch(getEquityMonitoringData(projectId));
    }
  }, [popover, detailsDrawer, currentItem?._id, currentItem?.project?._id, partnerId, dispatch, projectId]);

  const unlistOptions: OptionType[] = useMemo(() => {
    return [
      {
        label: `Unlist Project From ${currentItem?.name} marketplace`,
        icon: 'delete',
        color: 'error.main',
        itemOnClickHandler: unlistClickHandler,
      },
    ];
  }, [currentItem?.name, unlistClickHandler]);

  const additionalSeriesValues = useMemo(() => {
    const marketplacesFilter = marketplaces.map(item => item.partnerName || '');
    marketplacesFilter.unshift('All Marketplaces');
    return marketplacesFilter;
  }, [marketplaces]);

  const onChangeMarketplaceFilter = (value: string) => {
    const currentMarketplace = marketplaces.find(item => item.partnerName === value);
    setMarketplaceFilter(value);
    if (projectId && currentMarketplace) {
      dispatch(getEquityMonitoringData(projectId, currentMarketplace._id));
      return;
    }
    dispatch(getEquityMonitoringData(projectId));
  };

  const addMarketplaceClickHandler = () => {
    router.push(paths.readyForInvestors.equityPartners(leadId));
  };

  const noTableData = projects.length === 0;

  return (
    <Stack gap={5}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <FundingActivity
            showAdditionalFilter
            additionalSeriesValues={additionalSeriesValues}
            chartData={chartData}
            customAdditionalSeriesValue={marketplaceFilter}
            updateCustomAdditionalSeriesValue={onChangeMarketplaceFilter}
          />
        </Grid>
        <Grid item xs={4}>
          <FundingTotal
            showFilter
            updateCustomSeriesValue={onChangeMarketplaceFilter}
            customSeriesValue={marketplaceFilter}
            seriesValues={additionalSeriesValues}
            customTotal={funding?.totalRequired}
            title='Overview'
            funding={funding}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={
              <Stack pb={3} direction='row' justifyContent='space-between'>
                <Typography variant='h6'>Equity Partners</Typography>
                <Button variant='outlined' onClick={addMarketplaceClickHandler}>Share project</Button>
              </Stack>
            }
            />
            <TableCustom
              headLabels={marketplaces_table_head}
              noDataFound={noTableData}
              bodyCells={
                <>
                  {projects.map((tableItem, index) => {
                    return (
                      <StyledTableRow
                        key={index}
                      >
                        <TableCell>
                          <ListItemCustom
                            showAvatar
                            avatarSx={{ borderRadius: 1.5 }}
                            avatarAlt={tableItem.name}
                            avatarSrc={tableItem.logo?.url}
                            primaryText={tableItem.name}
                            secondaryText={tableItem.marketPlaceType}
                            listItemTextProps={{ primaryTypographyProps: { typography: 'body2' } }}
                            secondaryTextTypographySx={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <ListItemText
                            primary={tableItem.views}
                            secondary={fDate(tableItem.published)}
                            primaryTypographyProps={{
                              typography: 'body2',
                              ml: 'auto',
                            }}
                            secondaryTypographyProps={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                              ml: 'auto',
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <ListItemText
                            primary={fNumberWithDefaultCurrency(tableItem.secured)}
                            secondary={fNumberWithDefaultCurrency(tableItem.softCommitted)}
                            primaryTypographyProps={{
                              typography: 'body2',
                              ml: 'auto',
                            }}
                            secondaryTypographyProps={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                              ml: 'auto',
                            }}
                          />
                        </TableCell>
                        <TableCell align='right'>
                          <ListItemText
                            primary={fNumberWithDefaultCurrency(tableItem.fee)}
                            secondary={fPercent((tableItem.fee || 0) / 100_000)}
                            primaryTypographyProps={{
                              typography: 'body2',
                              ml: 'auto',
                            }}
                            secondaryTypographyProps={{
                              component: 'span',
                              typography: 'caption',
                              color: 'text.disabled',
                              ml: 'auto',
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size='small'
                            variant='soft'
                            color='default'
                            label={tableItem.investmentType}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            size='small'
                            disabled={tableItem.offerStatus === 'Unlisted'}
                            variant={allChipVariants[tableItem.offerStatus]}
                            color={allChipColors[tableItem.offerStatus]}
                            label={tableItem.offerStatus}
                          />
                        </TableCell>
                        <TableCell>
                          <LoadingButton
                            loading={loadDetails.value}
                            size='small'
                            variant='outlined'
                            onClick={detailsOnClickHandler(tableItem._id)}
                          >
                            Details
                          </LoadingButton>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              }
            />
          </Card>
        </Grid>
      </Grid>
      <DrawerCustom
        containerSx={{ width: 587 }}
        open={detailsDrawer.value}
        onCloseDrawerHandler={detailsDrawer.onFalse}
        headChildren={
          <>
            <ListItemCustom
              showAvatar
              avatarSx={{ borderRadius: 1.5 }}
              avatarAlt={currentItem?.name}
              avatarSrc={currentItem?.logo?.url}
              primaryText={currentItem?.name}
              secondaryChildren={
                <>
                  <Link
                    color='#3698AF'
                    underline='none'
                    target='_blank'
                    href={paths.investorPortal.projects.list}
                  >
                    Marketplace
                  </Link>
                </>
              }
              listItemTextProps={{ primaryTypographyProps: { typography: 'body2' } }}
            />
            <Chip
              size='small'
              disabled={currentItem?.offerStatus === 'Unlisted'}
              variant={allChipVariants[currentItem?.offerStatus || 'Ongoing Offerings']}
              color={allChipColors[currentItem?.offerStatus || 'Ongoing Offerings']}
              label={currentItem?.offerStatus}
            />
            <MoreOptions
              options={unlistOptions}
              popoverOnClose={popover.onClose}
              popoverOnOpen={popover.onOpen}
              popoverOpen={popover.open}
            />
          </>
        }
        headChildrenSx={{
          p: '16px 8px 16px 20px',
          borderBottom: (theme) => `1px solid ${alpha(theme.palette.text.secondary, 0.2)}`,
        }}
        bodyChildren={
          <Card>
            <CardHeader
              title='Funding Details'
              sx={{ p: 3 }}
              action={
                <Button
                  variant='outlined'
                  onClick={currentItem?.project?._id ? openInNewTabHandler(paths.investorPortal.projects.details(currentItem?.project?._id)) : undefined}
                >
                  Open Listing
                </Button>
              }
            />
            <FundingTable tableItems={currentItem?.investors} />
          </Card>
        }
        bodyChildrenSx={{ maxWidth: 587 }}
      />
    </Stack>
  );
};
