import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DrawerCustom, Iconify, TabContextCustom, TabType, UploadedFilesType } from 'src/components';
import { UseBooleanReturnType, usePortalFlags } from 'src/hooks';
import { addNewReportSettings, saveReportSettings, setPreviewDetailsData } from 'src/store/storage';

import { AssetDocumentsType, CoverImagesType, DetailViewData, NoteSettingType, ReportKeyType, ReportSettingsType } from '../../../../../types';
import { getAllSectionsConfig, getDocumentsWithAttached, getFullSectionConfig, getSettingConfig } from '../../report-detail/helpers';
import { Checkboxes } from './checkboxes';
import { notesList, reportList } from './data';
import { Files } from './files';
import { Images } from './images';
import { SummaryText } from './text';

type EditDrawerProps = {
  drawer: UseBooleanReturnType;
  reportName: ReportKeyType;
  type: ReportKeyType;
  isPreview?: boolean;
  reportSettings: ReportSettingsType[];
  detailsData: DetailViewData;
}

export const ReportEditDrawer = (props: EditDrawerProps) => {
  const {
    drawer,
    reportName,
    type,
    reportSettings,
    detailsData,
    isPreview,
  } = props;

  const {
    id,
    identifier = '',
  } = useParams<{
    id: string,
    identifier?: string
  }>();

  const isBank = type === 'bank';
  const isSupplier = type === 'supplier';

  const theme = useTheme();
  const { isCalculatorReport: isCalculatorReportFlag } = usePortalFlags();
  const isCalculatorReport = isCalculatorReportFlag || isPreview;

  const [currentTab, setCurrentTab] = useState<string>('sections');
  const [selectedSections, setSelectedSections] = useState<NoteSettingType[]>([]);
  const [noteList, setNoteList] = useState<NoteSettingType[]>([]);
  const [summary, setSummary] = useState<string>('');
  const [files, setFiles] = useState<UploadedFilesType[]>([]);
  const [documentsModalList, setDocumentsModalList] = useState<UploadedFilesType[]>([]);
  const [coverImages, setCoverImages] = useState<CoverImagesType[]>([]);
  const [newCoverImageId, setNewCoverImageId] = useState<string>('');
  const [coverImagesToDelete, setCoverImagesToDelete] = useState<CoverImagesType[]>([]);

  const dispatch = useDispatch();
  const assets = detailsData.assets;

  const applySettings = useCallback(() => {
    const isSettingExist = getSettingConfig(reportSettings, reportName, id, identifier);
    if (isSettingExist) {
      setSelectedSections(isSettingExist.sections || reportList);
      setNoteList(isSettingExist.notes || notesList);
      setSummary(isSettingExist.summaryText || '');
      setFiles(isSettingExist.files || []);
      setCoverImages(isSettingExist.images || []);
    }
  }, [identifier, id, reportName, reportSettings]);

  const initSetting = useCallback(() => {
    dispatch(addNewReportSettings({
      leadId: id,
      identifier: identifier,
      reportName: reportName,
      sections: reportList,
      notes: notesList,
      summaryText: '',
      files: [],
      images: assets?.marketPlaceListing?.coverImages,
    }));
  }, [assets?.marketPlaceListing?.coverImages, identifier, dispatch, id, reportName]);

  useEffect(() => {
    const isSettingExist = getSettingConfig(reportSettings, reportName, id, identifier);
    isSettingExist ? applySettings() : initSetting();
  }, [applySettings, identifier, id, initSetting, reportName, reportSettings]);

  const loadDocuments = useCallback(() => {
    const documents = detailsData.documents || detailsData.files as AssetDocumentsType[];
    setDocumentsModalList(documents?.map(document => ({
      ...document,
      size: document.filesize,
      filename: document.sectionFilename,
    })).filter(doc => doc.url) || []);
  }, [detailsData.documents, detailsData.files]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const tabs = useMemo<TabType[]>(() => {
    return [
      {
        label: 'Sections',
        value: 'sections',
      },
      {
        label: 'Images',
        value: 'images',
        hidden: isBank || isSupplier,
      },
      {
        label: 'Files',
        value: 'files',
      },
      {
        label: 'Texts',
        value: 'summary',
        hidden: isCalculatorReport,
      },
      {
        label: 'Texts',
        value: 'notes',
        hidden: !isCalculatorReport,
      },
    ];
  }, [isBank, isSupplier, isCalculatorReport]);

  const tabPanels = useMemo(() => [
    {
      tabKey: 'sections',
      component: <Checkboxes
        setSelected={setSelectedSections}
        selected={selectedSections}
        isPreview={isPreview}
      />,
    },
    {
      tabKey: 'images',
      component: <Images
        coverImages={coverImages}
        setCoverImages={setCoverImages}
        setCoverImagesToDelete={setCoverImagesToDelete}
        coverImagesToDelete={coverImagesToDelete}
        setNewCoverImageId={setNewCoverImageId}
      />,
    },
    {
      tabKey: 'files',
      component: <Files
        documentsList={documentsModalList}
        setDocumentsModalList={setDocumentsModalList}
        alreadySelectedDocuments={files}
        updateSelectedDocuments={setFiles}
      />,
    },
    {
      tabKey: 'summary',
      component: <SummaryText text={summary} setText={setSummary} />,
    },
    {
      tabKey: 'notes',
      component: <Checkboxes
        isNotesSection
        isPreview={isPreview}
        setSelected={setNoteList}
        selected={noteList}
        documentsModalList={documentsModalList}
        setDocumentsModalList={setDocumentsModalList}
      />,
    },
  ], [coverImages, coverImagesToDelete, documentsModalList, files, isPreview, noteList, selectedSections, summary]);

  const handleTabOnChange = (_: SyntheticEvent, value: string) => {
    setCurrentTab(value);
  };

  const onSaveClickHandler = () => {
    drawer.onFalse();
    const settings = {
      leadId: id,
      identifier,
      coverImagesToDelete,
      newCoverImageId,
      reportName,
      sections: selectedSections,
      notes: noteList,
      summaryText: summary,
      files,
      images: coverImages,
    };
    if (isPreview && detailsData.assets?._id) {
      const filesInNotesSection = _.flatMap(settings.notes, 'files');
      const finalFiles = _.uniqBy([...settings.files || [], ...filesInNotesSection], '_id') as AssetDocumentsType[];
      const newCoverImages = newCoverImageId ? coverImages.map(coverImage => ({
        ...coverImage,
        isCoverImage: coverImage._id === newCoverImageId,
      })) : coverImages;

      dispatch(setPreviewDetailsData({
        ...detailsData,
        assets: {
          ...detailsData.assets,
          marketPlaceListing: {
            ...detailsData.assets.marketPlaceListing,
            newCoverImageId: newCoverImageId || '',
            coverImagesToDelete: [...(detailsData.assets.marketPlaceListing?.coverImagesToDelete || []), ...coverImagesToDelete],
            coverImages: newCoverImages,
            description: getFullSectionConfig(settings, 'Project Summary').note,
          },
          documents: getDocumentsWithAttached(finalFiles, settings),
          sections: getAllSectionsConfig(settings),
        },
      }));
      dispatch(addNewReportSettings({
        ...settings,
        images: newCoverImages?.map(img => ({
          ...img,
          file: undefined,
        })),
        files: finalFiles,
      }));
      return;
    }
    dispatch(saveReportSettings(settings, settings => {
      setCoverImages(settings.images || []);
      setCoverImagesToDelete(settings.coverImagesToDelete);
      setNewCoverImageId(settings.newCoverImageId);
    }));
  };

  const onCloseDrawerHandler = () => {
    drawer.onFalse();
    applySettings();
    setCurrentTab('sections');
  };

  return (
    <DrawerCustom
      containerSx={{ width: 480 }}
      open={drawer.value}
      onCloseDrawerHandler={onCloseDrawerHandler}
      headChildren={
        <Stack
          width='100%'
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap={2}
        >
          <Typography variant='h6'>Edit</Typography>
          <IconButton onClick={drawer.onFalse}>
            <Iconify icon='mingcute:close-line' />
          </IconButton>
        </Stack>
      }
      bodyChildrenSx={{ pt: 0 }}
      bodyChildren={
        <TabContextCustom
          tabs={tabs}
          defaultTabValue='overview'
          handleTabOnChange={handleTabOnChange}
          tabsSX={{
            pl: '24px',
            ml: '-24px',
            width: 'calc(100% + 48px)',
          }}
          tabPanels={tabPanels}
          tabPanelsSx={{
            mt: 3,
            px: 0,
          }}
          currentTabValue={currentTab}
        />
      }
      footerChildrenSx={{ borderTop: `1px solid ${theme.palette.divider}` }}
      footerChildren={
        <Button
          variant='contained'
          size='large'
          fullWidth
          onClick={onSaveClickHandler}
        >
          Save
        </Button>
      }
    />
  );
};
