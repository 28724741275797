import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { TableHeadType } from 'src/features/leads-page';

export const TABLE_HEAD: TableHeadType[] = [
  {
    _id: 'displayAddress',
    label: 'Name',
    width: 420,
  },
  {
    _id: 'totalFloorAreaMeter',
    label: 'Size',
    width: 160,
    align: 'right',
  },
  {
    _id: 'price',
    label: DEFAULT_CURRENCY,
    width: 160,
    align: 'right',
  },
  {
    _id: 'propertySubType',
    label: 'Type',
    width: 160,
  },
];
