import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FundingActivity, FundingTotal } from 'src/features/marketplace-details';

import { FundingDataType, FundingType, InvestmentActivityListResponse } from '../../../../../../../../types';
import { OfferingActivityList } from './offering-activity-list/offering-activity-list';
import { OfferingSummary } from './offering-summary';

type OfferingProps = {
  isPartnerOffering?: boolean;
  chartData?: FundingDataType
  funding?: FundingType;
  activityData?: InvestmentActivityListResponse;
}

export const Offering = (props: OfferingProps) => {
  const {
    isPartnerOffering,
    chartData,
    funding,
    activityData,
  } = props;

  const loadTableDataFn = async () => {
    return activityData;
  };

  return (
    <Stack gap={5}>
      <Stack direction='row' gap={3}>
        <Box
          sx={{
            flex: 1,
            maxWidth: 220,
          }}
        >
          <OfferingSummary />
        </Box>
        <Box sx={{ flex: 1 }}>
          <FundingActivity
            title={isPartnerOffering ? 'Funding Activity' : 'Activity'}
            chartData={chartData}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            maxWidth: 320,
          }}
        >
          <FundingTotal
            title={isPartnerOffering ? 'Funding Overview' : 'Overview'}
            funding={funding}
          />
        </Box>
      </Stack>
      <OfferingActivityList
        loadTableDataFn={loadTableDataFn}
        isPartnerOffering={isPartnerOffering}
      />
    </Stack>
  );
};
