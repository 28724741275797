import _ from 'lodash';
import { paths } from 'src/config';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';

import { PartnerItemType, PartnersListType } from '../../../types';

export const removeSubstrings = (subStrings: string[], str: string, removeFromStartOfSentence = false) => {
  let regexPattern = `(${subStrings.join('|')})`;

  if (removeFromStartOfSentence) {
    regexPattern = `(^|\\.\\s)${regexPattern}`;
  }

  const regex = new RegExp(regexPattern, 'gi');

  return str.replace(regex, '');
};

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;
  const trimmedText = text.trim();
  return trimmedText.charAt(0).toUpperCase() + trimmedText.slice(1);
};

export const capitalizeFirstLetters = (text: string = '') => {
  if (!text) return text;
  const trimmedText = text.trim();
  const words = _.words(trimmedText);
  const formattedWords = _.map(words, word => capitalizeFirstLetter(word));
  return _.join(formattedWords, ' ');
};

export const splitAndCapitalize = (input: string): string => {
  const words = _.words(input);
  const formattedWords = _.map(words, word => _.capitalize(word));
  return _.join(formattedWords, ' ');
};

export const limitLength = (value: string, length: number = 13) => {
  const filteredValue = value.replace(/[^\d.]/g, '');
  return filteredValue.slice(0, length);
};

type ConvertToNumber<T> = {
  [key in keyof T]: number;
};

export const convertToNumber = <T>(obj: T): ConvertToNumber<T> | null => {
  const result = {} as ConvertToNumber<T>;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = (obj[key] || 0).toString();
      const numValue = _.toNumber(_.replace(value, /,/g, ''));
      if (_.isNaN(numValue)) {
        return null;
      } else {
        result[key] = numValue;
      }
    }
  }

  return result;
};

export const showUnspecifiedValue = (value?: string | number) => {
  return value || UNSPECIFIED_VALUE;
};

export const formatFullName = (firstName?: string, lastName?: string) => {
  const parts = _.compact([firstName, lastName]);

  return parts.length > 0 ? parts.join(' ') : UNSPECIFIED_VALUE;
};

export const extractFileExtension = (filename?: string): string => {
  const dotIndex = filename?.lastIndexOf('.');
  return (dotIndex === -1 || dotIndex === 0 ? '' : filename?.slice(dotIndex)) || '';
};

export const onlyNumbers = (value?: string | number, allowedChars = ''): string => {
  const formattedValue = String(value);
  const reg = new RegExp(`[^0-9${allowedChars}]`, 'g');
  return formattedValue.trim().replace(reg, '');
};

export const convertToOnlyNumber = (value?: string | number | null, allowedChars = '.'): number => {
  if (value === null) return 0;
  const numericValue = onlyNumbers(value, allowedChars);
  const numberValue = Number(numericValue);
  const truncatedValue = Math.floor(numberValue * 100) / 100;
  return truncatedValue;
};

export const getMarketplaceUrl = (id: string) => {
  const url = window.location.origin;
  return `${url}/investor/projects/${id}/overview`;
};

export const getPartnerProfileUrl = (partner: PartnerItemType) => {
  const pathsObj: Record<PartnersListType, string> = {
    'Equity Partner': paths.equityPartners.list,
    Bank: paths.bankPartners.products(partner._id),
    Supplier: paths.supplierPartners.products(partner._id),
    Investor: '',
    Developer: '',
    Other: '',
  };
  return pathsObj[partner.companyType || 'Equity Partner'];
};
