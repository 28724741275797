import { InvestmentActivityListResponse, InvestmentProject, InvestorDashboardType } from '../../../../types';
import { apiFetch } from '../api';
import { uploadFileToStorage } from '../storage';
import {
  CreateInvestmentActivityType,
  GetInvestorInvestmentActivityParams,
  SendInvoiceBodyType,
  SendReceiptBodyType,
  SendRejectedSoftCommitmentBodyType,
  SendSignedSoftCommitmentBodyType,
  SendSoftCommitmentBodyType,
  UpdateInvestmentActivityType,
} from './types';

export const getDashboardData = async (partnerId: string) => {
  try {
    const response: InvestorDashboardType = await apiFetch('investor/dashboard', {
      method: 'get',
      headers: { partnerId },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getInvestorProjectsData = async (partnerId: string) => {
  try {
    const response: InvestmentProject[] = await apiFetch('investor/projects-list', {
      method: 'get',
      headers: { partnerId },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createInvestmentActivity = async (body: CreateInvestmentActivityType, partnerId: string, offeringId: string = '') => {
  try {
    const response: {
      _id: string,
      error?: string;
    } = await apiFetch(`partners-portal/investment-activity-create/${offeringId}`, {
      method: 'post',
      body: JSON.stringify(body),
      returnError: true,
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteInvestmentActivity = async (partnerId: string, offeringId: string = '', investmentActivityId: string = '') => {
  try {
    return await apiFetch('partners-portal/investment-activity-delete', {
      method: 'post',
      body: JSON.stringify({
        offeringId,
        investmentActivityId,
      }),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getInvestorInvestmentActivityList = async (partnerId: string, paramBody: GetInvestorInvestmentActivityParams) => {
  try {
    const response: InvestmentActivityListResponse = await apiFetch('investor/investment-activity-list', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateInvestmentActivity = async (partnerId: string, body: UpdateInvestmentActivityType) => {
  try {
    const {
      activity,
      file,
      kycState,
      email = '',
    } = body;
    const investmentActivityId = activity._id || '';
    const offeringId = activity.offeringId || '';

    const initialStatus = activity.status;
    const isReserved = initialStatus === 'Reserved';
    const isCommitmentReadyForSign = initialStatus === 'Soft commitment ready for sign';
    const isCommitmentSigned = initialStatus === 'Commitment Signed';
    const isInvoiceReceived = initialStatus === 'Invoice Received';

    let uploadedFile = null;
    if (file?.file) {
      uploadedFile = await uploadFileToStorage({
        ...file,
        type: 'Documents',
      }, partnerId);
    }

    const sendSoftCommitmentBody: SendSoftCommitmentBodyType | null = (isReserved && kycState === 'Approved' && uploadedFile) ? {
      fileSoftCommitmentContract: uploadedFile,
      isSoftCommitmentSigned: false,
      isRejected: false,
      isKycVerified: kycState === 'Approved',
      isReserved: true,
    } : null;

    const sendRejectedSoftCommitmentBody: SendRejectedSoftCommitmentBodyType | null = (isReserved && kycState === 'Rejected') ? { isRejected: true } : null;

    const sendSignedSoftCommitmentBody: SendSignedSoftCommitmentBodyType | null = (isCommitmentReadyForSign && uploadedFile) ? {
      fileSoftCommitmentContract: uploadedFile,
      isSoftCommitmentSigned: true,
    } : null;

    const sendInvoiceBody: SendInvoiceBodyType | null = (isCommitmentSigned && uploadedFile) ? {
      fileInvoice: uploadedFile,
      isInvoiceSent: true,
      email: email,
    } : null;

    const sendReceiptBody: SendReceiptBodyType | null = (isInvoiceReceived && uploadedFile) ? {
      fileRecipe: uploadedFile,
      isFundSecured: true,
    } : null;

    const bodyForRequest = sendRejectedSoftCommitmentBody || sendSoftCommitmentBody || sendSignedSoftCommitmentBody || sendInvoiceBody || sendReceiptBody;

    const response: {
      _id: string
    } = await apiFetch(`partners-portal/investment-activity-create/${offeringId}/${investmentActivityId}`, {
      method: 'post',
      body: JSON.stringify(bodyForRequest),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
