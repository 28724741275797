import _, { omit } from 'lodash';
import { Dispatch } from 'react';
import { UNSPECIFIED_VALUE } from 'src/constants/constants';
import { DebtRequestType } from 'src/features/debt-request';

import { DocumentListResponse, FinanceUploadFileType, FinancialTableType, OptionBlockKey } from '../../../../types';
import { apiFetch } from '../api';
import { setIsLoading, updateSnackbarConfig } from '../storage';
import { AppThunk } from '../store';
import { addOffer, deleteItem, setOffers, setPreliminaryDebtRequests, setTermSheets, termSheetRequest, updateOffer, updateRequestItem, uploadFileToTermSheet } from '.';
import {
  CreateDebtRequestType,
  CreateDocumentRequestType,
  DebtDetailsResponse,
  DefaultDebtResponseType,
  FinancialListResponse,
  TermSheetDetailsResponseType,
  TermSheetRequestActionType,
  UpdateDebtRequestBodyType,
  UpdateDebtRequestType,
} from './types';

export const createDocument = async (paramBody: CreateDocumentRequestType, partnerId: string) => {
  try {
    const documentIds: string[] = await apiFetch('document', {
      method: 'post',
      body: JSON.stringify(paramBody),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });
    return documentIds;
  } catch (error) {
    console.error(error);
  }
};

export const uploadDocument = async (file: FinanceUploadFileType, partnerIdOwner: string, partnerId?: string): Promise<FinanceUploadFileType | undefined> => {
  try {
    const response: { url: string } = await apiFetch(`document/upload/${file._id}${partnerId ? `/${partnerId}` : ''}`, {
      method: 'post',
      headers: {
        'Content-Type': file.file?.type || file.filetype || 'image/png',
        partnerId: partnerIdOwner,
      },
      body: file.file,
    });
    return {
      ...file,
      url: response.url,
    };
  } catch (error) {
    console.error(error);
  }
};

export const getDocumentsList = async (queryName: string, id: string, partnerId: string): Promise<DocumentListResponse[]> => {
  try {
    return await apiFetch(`document/list/${queryName}/${id}`, {
      method: 'get',
      headers: { partnerId },
    });
  } catch (error) {
    console.error(error);
    return [] as DocumentListResponse[];
  }
};

export const deleteDocument = async (documentId: string, partnerId: string, retainWithProject: boolean = false) => {
  try {
    await apiFetch(`document/delete/${documentId}/${retainWithProject}`, {
      method: 'delete',
      headers: { partnerId },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createDebtRequestThunk = (projectId: string, callBack: (debtRequest: CreateDebtRequestType) => void): AppThunk => async (_, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    const debtRequest: CreateDebtRequestType = await apiFetch('debt-request', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify({ projectId }),
    });
    callBack?.(debtRequest);
  } catch (error) {
    console.error(error);
  }
};

export const getDebtRequestDetails = async (debtId: string, dispatch: Dispatch<unknown>, partnerId: string): Promise<DebtRequestType | undefined> => {
  try {
    dispatch(setIsLoading(true));
    const detailsResponse: DebtDetailsResponse = await apiFetch(`debt-request/details/${debtId}`, {
      method: 'get',
      headers: { partnerId },
    });
    dispatch(setIsLoading(false));
    if (detailsResponse) {
      const seniorDebt = detailsResponse.debts.find(debt => debt.section.includes('Senior'));
      const juniorDebt = detailsResponse.debts.find(debt => debt.section.includes('Junior'));
      const bridgeDebt = detailsResponse.debts.find(debt => debt.section.includes('Bridge'));
      const otherDebt = detailsResponse.debts.find(debt => debt.section.includes('Other'));
      return {
        documentList: detailsResponse.documents || [],
        costsBreakdown: detailsResponse.project.scenario?.calculate?.output['Finance costs breakdown'] || [],
        seniorDebt: seniorDebt ? {
          _id: seniorDebt?._id,
          selectedBanks: seniorDebt.partnerIds.map(bank => ({
            ...bank,
            selected: true,
          })),
          emailNotes: seniorDebt.note || '',
          detailsList: {
            ...seniorDebt,
            rate: seniorDebt.interestRate,
          },
        } : undefined,
        juniorDebt: juniorDebt ? {
          _id: juniorDebt?._id,
          selectedBanks: juniorDebt.partnerIds.map(bank => ({
            ...bank,
            selected: true,
          })),
          emailNotes: juniorDebt.note || '',
          detailsList: {
            ...juniorDebt,
            rate: juniorDebt.interestRate,
          },
        } : undefined,
        bridgeDebt: bridgeDebt ? {
          _id: bridgeDebt?._id,
          selectedBanks: bridgeDebt.partnerIds.map(bank => ({
            ...bank,
            selected: true,
          })),
          emailNotes: bridgeDebt.note || '',
          detailsList: {
            ...bridgeDebt,
            rate: bridgeDebt.interestRate,
          },
        } : undefined,
        otherDebt: otherDebt ? {
          _id: otherDebt?._id,
          selectedBanks: otherDebt.partnerIds.map(bank => ({
            ...bank,
            selected: true,
          })),
          emailNotes: otherDebt.note || '',
          detailsList: {
            ...otherDebt,
            rate: otherDebt.interestRate,
          },
        } : undefined,
      };
    }
  } catch (error) {
    console.error(error);
    dispatch(setIsLoading(false));
  }
};

export const updateDebtRequest = async (debtId: string, fieldsToUpdate: Partial<UpdateDebtRequestType>, partnerId: string, status?: string) => {
  try {
    const updatedBody: UpdateDebtRequestBodyType = { debts: [{ ...fieldsToUpdate }] };
    if (status) {
      updatedBody.status = status;
    }
    await apiFetch(`debt-request/update/${debtId}`, {
      method: 'post',
      body: JSON.stringify(updatedBody),
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
    });

  } catch (error) {
    console.error(error);
  }
};

export const loadFinancialData = (projectId?: string): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    if (!projectId) {
      dispatch(setPreliminaryDebtRequests([]));
      dispatch(setOffers([]));
      dispatch(setTermSheets([]));
      return;
    }

    const response: FinancialListResponse = await apiFetch(`debt-request/preliminary/${projectId}`, {
      method: 'get',
      headers: { partnerId },
    });
    const formattedPreliminaryRequests: FinancialTableType[] = response.preliminary.debtRequests.map(debt => {
      return {
        _id: debt.debt._id,
        debtRequestId: debt.debtRequest._id,
        debtToDeleteId: debt.debt._id,
        type: debt.debt.section,
        bankSrc: debt.partner.logoUrl,
        bankId: debt.partner._id,
        bankName: debt.partner.companyName || '',
        bankContactName: debt.partner.companyContactName,
        bankContactEmail: debt.partner.companyContactEmail,
        preliminaryDebtRequest: {
          ...debt.debt,
          _id: debt.debt._id,
          rate: debt.debt.interestRate,
          date: debt.updatedAt,
        },
      };
    });
    const formattedOffers: FinancialTableType[] = response.offers.map(offer => {
      return {
        _id: offer.offer._id,
        debtRequestId: offer.debtRequest._id,
        debtToDeleteId: offer.debt._id,
        type: offer.offer.section,
        bankId: offer.partner._id,
        bankSrc: offer.partner.logoUrl,
        bankName: offer.partner.companyName || '',
        bankContactName: offer.partner.companyContactName,
        bankContactEmail: offer.partner.companyContactEmail,
        offer: {
          ...offer.offer,
          _id: offer.offer._id,
          rate: offer.offer.interestRate,
          date: offer.updatedAt,
        },
      };
    });
    const formattedTermSheets: FinancialTableType[] = response.termSheets.map(termSheet => {
      return {
        _id: termSheet.termSheet._id,
        debtRequestId: termSheet.debtRequest._id,
        debtToDeleteId: termSheet.debt._id,
        type: termSheet.termSheet.section,
        bankId: termSheet.partner._id,
        bankSrc: termSheet.partner.logoUrl,
        bankName: termSheet.partner.companyName || '',
        bankContactName: termSheet.partner.companyContactName,
        bankContactEmail: termSheet.partner.companyContactEmail,
        status: termSheet.termSheet.status === 'Uploaded' ? 'Signed' : termSheet.termSheet.status,
        offer: {
          ...termSheet.termSheet,
          _id: termSheet.termSheet._id,
          rate: termSheet.termSheet.interestRate,
          date: termSheet.updatedAt,
        },
        termSheet: {
          ...termSheet.termSheet,
          _id: termSheet.termSheet._id,
          rate: termSheet.termSheet.interestRate,
          date: termSheet.updatedAt,
        },
      };
    });
    dispatch(setPreliminaryDebtRequests(formattedPreliminaryRequests));
    dispatch(setOffers(formattedOffers));
    dispatch(setTermSheets(formattedTermSheets));
  } catch (error) {
    console.error(error);
  }
};

export const addOfferThunk = (body: DefaultDebtResponseType, debtRequestId: string): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    const response: { _id: string } = await apiFetch(`debt-request/offer/${body._id}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify(omit(body, ['rate', 'section', '_id', 'bankContactName', 'bankContactEmail'])),
    });

    if (response._id) {
      dispatch(addOffer({
        preliminaryDebtRequestId: debtRequestId,
        partnerId: body.partnerId,
        bankContactName: body.bankContactName,
        bankContactEmail: body.bankContactEmail,
        offerId: response._id,
        offerDetails: {
          ...body,
          rate: body.interestRate,
        },
      }));
      dispatch(updateSnackbarConfig({ title: `${body.section} Debt Offer Added` }));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateOfferThunk = (body: DefaultDebtResponseType, debtRequestId: string): AppThunk => async (dispatch, getState) => {
  const partnerId = getState().Auth.user?.partnerId || '';

  try {
    const response: { _id: string } = await apiFetch(`debt-request/update-offer/${body._id}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify(omit(body, ['rate', 'section', '_id', 'bankContactName', 'bankContactEmail'])),
    });
    if (response._id) {
      dispatch(updateOffer({
        preliminaryDebtRequestId: debtRequestId,
        partnerId: body.partnerId,
        offerId: body._id,
        bankContactName: body.bankContactName,
        bankContactEmail: body.bankContactEmail,
        offerDetails: {
          ...body,
          rate: body.interestRate,
        },
      }));
    }

  } catch (error) {
    console.error(error);
  }
};

export const addTermSheetThunk = (body: TermSheetRequestActionType, bankId: string = ''): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    const response = await apiFetch(`debt-request/term-sheet/${body.debtRequestId}/${body._id}/${bankId}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        partnerId,
      },
      body: JSON.stringify({
        name: body.termSheetDetails.contactPerson,
        email: body.termSheetDetails.contactEmail,
        message: body.termSheetDetails.message,
      }),
    });

    if (response) {
      dispatch(termSheetRequest({
        ...body,
        _id: response._id,
      }));
      dispatch(updateSnackbarConfig({ title: 'Term Sheet Requested' }));
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadFileToTermSheetThunk = (itemId: string, file: FinanceUploadFileType, partnerId: string, callback?: VoidFunction): AppThunk => async (dispatch, getState) => {
  const partnerIdOwner = getState().Auth.user?.partnerId || '';

  try {
    await uploadDocument({
      ...file,
      _id: itemId,
    }, partnerIdOwner, partnerId);
    dispatch(uploadFileToTermSheet({
      itemId,
      partnerId,
      file,
    }));
    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const deleteDebtThunk = (debt: FinancialTableType, block: OptionBlockKey): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    const response = await apiFetch(`debt-request/delete/${debt.debtToDeleteId}/${debt.bankId}`, {
      method: 'post',
      body: JSON.stringify({ block }),
      headers: { partnerId },
      returnError: true,
    });

    if (response.msg) {
      dispatch(deleteItem({
        itemId: debt._id,
        debtRequestId: debt.debtRequestId,
        step: block,
        partnerId: debt.bankId || '',
      }));
    }
  } catch (error) {
    console.error(error);
  }
};

export const loadTermSheetDetails = (item: FinancialTableType): AppThunk => async (dispatch, getState) => {
  try {
    const partnerId = getState().Auth.user?.partnerId || '';

    const response: TermSheetDetailsResponseType = await apiFetch(`debt-request/term-sheet-details/${item._id}/${item.bankId}`, {
      method: 'get',
      headers: { partnerId },
      returnError: true,
    });

    if (!_.isEmpty(response)) {
      dispatch(updateRequestItem({
        _id: item._id,
        termSheet: {
          date: response.termSheet.requested,
          contactEmail: response.termSheet.termSheetDetail.email,
          contactPerson: response.termSheet.termSheetDetail.name,
          file: {
            _id: response.termSheet.url || '',
            url: response.termSheet.url,
            filename: response.termSheet.filename || UNSPECIFIED_VALUE,
            filetype: response.termSheet.filetype,
            size: response.termSheet.filesize || 0,
            uploadedBy: response.termSheet.uploadedBy || UNSPECIFIED_VALUE,
            uploadedAt: response.termSheet.requested,
          },
        },
        offer: {
          ...response.bankOffer,
          rate: response.bankOffer.interestRate,
        },
        preliminaryDebtRequest: {
          ...response.preliminaryRequest,
          rate: response.bankOffer.interestRate,
        },
      }));
    }
  } catch (error) {
    console.error(error);
  }
};
