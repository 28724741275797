import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import _ from 'lodash';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomTab, TableCustom, TabType } from 'src/components';
import { paths } from 'src/config';
import { QUOTATION_REQUESTS_TABLE_HEAD } from 'src/features/tendering-page/mock-data';
import { useRouter, useTable } from 'src/hooks';
import { sortCustom } from 'src/utils';

import { CompanyPartnerType, QuotationType } from '../../../../../../../../types';
import { UploadFormDrawerSubmit } from '../quotations/upload-form-drawer';
import { defaultFilters, TableFilters, TableFilterValue } from './helpers';
import { QuotationRequestTableRow } from './quotation-request-table-row';

interface QuotationRequestsProps {
  requests: QuotationType[];
  deleteOnClickHandler: (quotationId: string) => void;
  addOfferSubmitFunction: (item: QuotationType) => (data: UploadFormDrawerSubmit) => void;
  totalFloorArea?: number;
}

export const QuotationRequests = (props: QuotationRequestsProps) => {
  const {
    totalFloorArea,
    requests,
    deleteOnClickHandler,
    addOfferSubmitFunction,
  } = props;

  const { id } = useParams<{
    id: string
  }>();

  const router = useRouter();
  const [filters, setFilters] = useState<TableFilters>(defaultFilters);

  const table = useTable();
  const dataFiltered: QuotationType[] = applyFilter(sortCustom(requests, table), filters);
  const noDataFound = dataFiltered?.length === 0;

  const geQuotationLength = useCallback((requestType: CompanyPartnerType) => {
    return requests?.filter((item) => item.requestType === requestType)?.length;
  }, [requests]);

  const TABS: TabType[] = useMemo(() => {
    return [
      {
        value: 'All',
        label: 'All',
        count: requests.length,
      },
      {
        value: 'General Contractor',
        label: 'General Contractor',
        count: geQuotationLength('General Contractor'),
      },
      {
        value: 'Factory',
        label: 'Factory',
        count: geQuotationLength('Factory'),
      },
      {
        value: 'Construction Company',
        label: 'Construction Company',
        count: geQuotationLength('Construction Company'),
      },
      {
        value: 'Development Partner',
        label: 'Development Partner',
        count: geQuotationLength('Development Partner'),
      },
      {
        value: 'Project Management',
        label: 'Project Management',
        count: geQuotationLength('Project Management'),
      },
    ];
  }, [geQuotationLength, requests.length]);

  const handleTabOnChange = useCallback((event: SyntheticEvent, newValue: TableFilterValue) => {
    setFilters({ type: newValue });
  }, []);

  const onNewRequestClick = () => {
    router.push(paths.tendering.details(id, 'tendering-request'));
  };

  return (
    <Card>
      <CardHeader
        sx={{ pb: 3 }}
        title='Quotation Request'
        action={
          <Button onClick={onNewRequestClick} variant='outlined' size='small'>
            New Request
          </Button>
        }
      />

      <CustomTab
        tabs={TABS}
        defaultTabValue={defaultFilters.type}
        currentTabValue={filters.type}
        handleTabOnChange={handleTabOnChange}
      />
      <TableCustom
        headLabels={QUOTATION_REQUESTS_TABLE_HEAD}
        table={table}
        rowCount={requests?.length}
        noDataFound={noDataFound}
        bodyCells={(
          <>
            {dataFiltered?.map((row) => (
              <QuotationRequestTableRow
                key={row._id}
                deleteOnClickHandler={deleteOnClickHandler}
                addOfferSubmitFunction={addOfferSubmitFunction(row)}
                totalFloorArea={totalFloorArea}
                row={row}
              />
            ))}
          </>
        )}
      />
    </Card>
  );
};

const applyFilter = (data: QuotationType[], filters: TableFilters) => {
  let resultData = _.cloneDeep(data);
  const { type } = filters;

  if (type !== 'All') {
    resultData = resultData?.filter((item) => item.requestType === type);
  }

  return resultData;
};
