import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { useRef } from 'react';
import { Controller, FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { fNumber, onlyNumbers } from 'src/utils';


type RHFTextFieldProps = TextFieldProps & {
  name: string;
  displayError?: boolean;
  useSeparators?: boolean;
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> | undefined
};

export const RHFTextField = (props: RHFTextFieldProps) => {
  const {
    name,
    displayError = true,
    useSeparators = false,
    helperText,
    rules,
    type,
    ...other
  } = props;
  const { control } = useFormContext();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Controller
      name={name}
      rules={{ ...rules }}
      control={control}
      render={({
        field,
        fieldState: { error },
      }) => {
        const value = field.value;
        return (
          <TextField
            {...field}
            fullWidth
            ref={ref}
            type={type}
            value={useSeparators ? fNumber(onlyNumbers(value), '', true) : value}
            onChange={(event) => {
              const value = event.target.value;
              if (type === 'number' && value !== '') {
                field.onChange(Number(value));
              } else {
                field.onChange(value);
              }
            }}
            error={!!error}
            helperText={(error && displayError) ? error?.message : helperText}
            {...other}
          />
        );
      }}
    />
  );
};

export interface HiddenRHFTextFieldProps {
  formName: string;
}

export const HiddenRHFTextField = (props: HiddenRHFTextFieldProps) => {
  const { formName } = props;
  return (
    <RHFTextField
      displayError={false}
      error={false}
      name={formName}
      sx={{
        width: 0,
        height: 0,
        overflow: 'hidden',
        position: 'absolute',
      }}
    />
  );
};
