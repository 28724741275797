import useTheme from '@mui/material/styles/useTheme';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BankingIcon } from 'src/assets/svg';
import { CrumbType, DetailView, generateSummaryField } from 'src/components';
import { paths } from 'src/config';
import { greenGradient } from 'src/constants/colors';
import { allChipColors } from 'src/constants/constants';
import { useRouter } from 'src/hooks';
import { RootState } from 'src/store';
import { createDebtRequestThunk, getDebtRequestDetails } from 'src/store/financial';
import { formatFullName } from 'src/utils';

import { FinancingDetailViewType } from '../../../../types';
import { DebtRequestType } from '../debt-request';

export const FinancingDetails = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const theme = useTheme();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const partnerId = useSelector((state: RootState) => state.Auth.user?.partnerId || '');
  const isLoading = useSelector((state: RootState) => state.Storage.isLoading);
  const [debtDetails, setDebtDetails] = useState<DebtRequestType | undefined>();
  const lead = detailsData?.lead;
  const scenario = detailsData?.project?.scenario;
  const financing = detailsData?.financing;
  const draftFinance = financing?.find(finance => finance.status === 'draft');
  const uploadedDocsLength = debtDetails?.documentList?.filter(doc => doc.url)?.length || 0;
  const allDocsLengths = debtDetails?.documentList?.length || 0;
  const cardTitle = draftFinance?._id
    ? `${uploadedDocsLength} of ${allDocsLengths} documents uploaded. ${uploadedDocsLength === allDocsLengths ? 'You can now proceed to send requests' : 'Wait for the landowner or upload yourself'}`
    : 'Everything is ready to start';

  const cardBtnTitle = draftFinance?._id
    ? `${uploadedDocsLength === allDocsLengths ? 'Review and Send Request' : 'Review'}`
    : 'Start';

  const getDebtDeTails = async (draftFinance: FinancingDetailViewType) => {
    const details = await getDebtRequestDetails(draftFinance._id, dispatch, partnerId);
    setDebtDetails(details);
  };

  useEffect(() => {
    if (draftFinance?._id && window.location.pathname.includes('overview')) {
      getDebtDeTails(draftFinance);
    }
    //   eslint-disable-next-line
  }, [draftFinance, window.location.pathname]);

  const crumbs: CrumbType[] = [
    {
      title: 'Packaging',
      href: paths.financing.list,
    },
    {
      title: 'Financing',
      href: paths.financing.list,
    },
    { title: lead?.location?.address },
  ];

  const debtRequestClickHandler = useCallback(() => {
    if (!draftFinance?._id) {
      dispatch(createDebtRequestThunk(detailsData.project?._id || '', (debtRequest) => {
        router?.push(`financing-debt-request?debtId=${debtRequest._id}`);
      }));
      return;
    }
    router.push(`financing-debt-request?debtId=${draftFinance?._id}`);
  }, [detailsData.project?._id, dispatch, draftFinance?._id, router]);


  const summaryItems = useMemo(() => {
    return generateSummaryField({
      projectType: lead?.location?.address,
      size: lead?.plotArea,
      projectManager: formatFullName(lead?.contactDetails?.firstName, lead?.contactDetails?.lastName),
    });
  }, [lead?.contactDetails?.firstName, lead?.contactDetails?.lastName, lead?.location?.address, lead?.plotArea]);

  return (
    <DetailView
      showToolbarBadge
      showOverviewCloud
      componentsViews='default'
      customSummaryItemsOnOverview={summaryItems}
      crumbs={crumbs}
      showData={!isLoading}
      toolbarTitle={lead?.location?.address}
      toolbarBadgeVariant='outlined'
      toolbarBadgeTitle={detailsData.financingStatus}
      toolbarBadgeColor={allChipColors[detailsData.financingStatus || 'New']}
      // todo: change note section
      noteSection='Financing'
      cloudProps={{
        showCloud: !financing?.some(fin => fin.status === 'completed'),
        cardSx: { background: greenGradient },
        avatar: <BankingIcon />,
        btnTitle: cardBtnTitle,
        title: 'Debt Request',
        subtitle: cardTitle,
        titleColor: theme.palette.success.darker,
        subTitleColor: theme.palette.success.darker,
        onBtnClickHandler: debtRequestClickHandler,
      }}
      scenario={scenario}
    />
  );
};
