import { AssetSections } from '../../../types';

export const defaultAssetsSections: AssetSections = {
  gallery: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  aboutProject: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  ourRequest: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  siteAndPurchase: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  proposedUnits: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  construction: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  finance: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  sales: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  timelineAndCashflow: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  sensitivityAnalysis: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  projectMultiples: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  localArea: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  localMarket: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
  comparables: {
    isActive: true,
    isNoteActive: true,
    note: '',
  },
};
