import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomSelect } from 'src/components';
import { DEFAULT_CURRENCY } from 'src/constants/constants';
import { RootState } from 'src/store';
import { setDetailViewData } from 'src/store/storage';
import { updateQuotation } from 'src/store/tendering';
import { convertToOnlyNumber, fNumber, onlyNumbers } from 'src/utils';

export const OverviewOffer = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const detailsData = useSelector((state: RootState) => state.Storage.detailsData);
  const quotation = detailsData.quotation;
  const offer = quotation?.preliminaryOffer;
  const scenario = detailsData.project?.scenario;
  const totalFloorArea = convertToOnlyNumber(scenario?.calculate?.output['Total floor area']);

  const disabled = !!quotation?.preliminaryOffer?.constructionCost;
  const constructionCostAmount = quotation?.constructionCostAmount;
  const psqmByDefault = totalFloorArea ? convertToOnlyNumber(constructionCostAmount) / convertToOnlyNumber(totalFloorArea) : '';

  const [costs, setCosts] = useState<string>(offer?.constructionCost?.toString() || String(constructionCostAmount) || '');
  const [psqm, setPsqm] = useState<string>(offer?.constructionPsqm?.toString() || String(psqmByDefault) || '');
  const [fee, setFee] = useState<string>(offer?.partnerFee?.toString() || '');
  const [buildTime, setBuildTime] = useState<string>(offer?.buildTime?.toString() || String(quotation?.buildTime) || '');

  const onTextFieldChange = (setter: Dispatch<SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setter(onlyNumbers(e.currentTarget.value));
  };

  const updateCost = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCosts(value);
    convertToOnlyNumber(totalFloorArea) && setPsqm(String(convertToOnlyNumber(value) / convertToOnlyNumber(totalFloorArea)));
  };

  const updatePsqm = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPsqm(value);
    convertToOnlyNumber(totalFloorArea) && setCosts(String(convertToOnlyNumber(value) * convertToOnlyNumber(totalFloorArea)));
  };

  const sendOfferHandler = () => {
    if (quotation) {
      dispatch(updateQuotation(quotation._id, {
        preliminaryOffer: {
          constructionPsqm: +psqm,
          constructionCost: +costs,
          buildTime: +buildTime,
          partnerFee: +fee,
        },
      }, () => {
        dispatch(setDetailViewData({
          ...detailsData,
          quotation: {
            ...quotation,
            preliminaryOffer: {
              ...offer,
              constructionPsqm: +psqm,
              constructionCost: +costs,
              buildTime: +buildTime,
              partnerFee: +fee,
            },
          },
        }));
      }));
    }
  };

  return (
    <Card sx={{ background: alpha(theme.palette.info.main, 0.08) }}>
      <CardContent>
        <Stack gap={3}>
          <Typography variant='h6'>
            Your Offer
          </Typography>

          <CustomSelect
            disabled
            selectSize='medium'
            value={[quotation?.requestType]}
            label='* Services'
          />
          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack gap={2}>
            <TextField
              value={costs ? fNumber(costs) : ''}
              onChange={updateCost}
              label={`Construction Costs, ${DEFAULT_CURRENCY}`}
              variant='outlined'
              fullWidth
              disabled={disabled}
            />
            <TextField
              value={psqm ? fNumber(psqm) : ''}
              onChange={updatePsqm}
              label='Construction Psqm, Kr'
              variant='outlined'
              fullWidth
              disabled={disabled}
            />
            <TextField
              value={buildTime ? fNumber(buildTime) : ''}
              onChange={onTextFieldChange(setBuildTime)}
              label='Build time (Months)'
              variant='outlined'
              fullWidth
              disabled={disabled}
            />
            {quotation?.requestType === 'Development Partner' &&
              <TextField
                required
                value={fee ? fNumber(fee) : ''}
                onChange={onTextFieldChange(setFee)}
                label='Partner fee,%'
                variant='outlined'
                fullWidth
                disabled={disabled}
              />
            }
          </Stack>

          <Button
            variant='contained'
            size='large'
            fullWidth
            disabled={disabled}
            onClick={sendOfferHandler}
          >
            Send Offer
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
