import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CrumbType, ProjectToolbar, TabContextCustom, TabPanelsType } from 'src/components';
import { paths } from 'src/config';
import { useRouter } from 'src/hooks';

import { MarketplaceOffer } from '../../../../types';
import { Dashboard } from './components/dashboard/dashboard';
import { Investors } from './components/investors/investors';
import { MarketplaceProjectsOfferings } from './components/marketplace-projects-offerings';
import { PartnersFees } from './components/partners-fees/partners-fees';
import { Settings } from './components/settings';
import { _mockMarketplaceDetails, _mockMarketplaceStats, EXTERNAL_TABS, partnerFees, TABS } from './mock-data';

export const MarketplaceDetails = () => {
  const router = useRouter();
  const theme = useTheme();

  const params = useParams<{ id: string, tab: string }>();
  const {
    id,
    tab,
  } = params;

  const detailData = _mockMarketplaceDetails(id);
  const isExternal = detailData.type === 'External';

  const dashboardStats = useMemo(() => isExternal
    ? [..._mockMarketplaceStats, partnerFees]
    : _mockMarketplaceStats, [isExternal]);

  const onOfferingClick = useCallback((offering: MarketplaceOffer) => {
    const currentPath = paths.marketplaces.projectDetail;
    router.push(currentPath(params.id, offering._id, 'offering'));
  }, [params.id, router]);

  const onViewAllOfferingsClick = useCallback(() => {
    router.push(paths.marketplaces.details(id, 'Projects'));
  }, [id, router]);

  const crumbs = useMemo<CrumbType[]>(() => [
    { title: 'Marketplaces' },
    {
      title: detailData.name,
      color: theme.palette.text.disabled,
    },
  ], [detailData.name, theme.palette.text.disabled]);

  const [currentTab, setCurrentTab] = useState<string>(tab);
  const tabPanels: TabPanelsType[] = useMemo(() => {
    return [
      {
        tabKey: 'Dashboard',
        component: <Dashboard
          stats={dashboardStats}
          onOfferingClick={onOfferingClick}
          onViewAllOfferingsClick={onViewAllOfferingsClick}
        />,
      },
      {
        tabKey: 'Projects',
        component: <MarketplaceProjectsOfferings isExternal={isExternal} />,
      },
      {
        tabKey: 'Partners fees',
        component: <PartnersFees />,
      },
      {
        tabKey: 'Investors',
        component: <Investors />,
      },
      {
        tabKey: 'Settings',
        component: <Settings />,
      },
    ];
  }, [dashboardStats, isExternal, onOfferingClick, onViewAllOfferingsClick]);

  const handleTabOnChange = (_: SyntheticEvent, value: string) => {
    setCurrentTab(value);
    router.replace(value);
  };

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  return (
    <Stack>
      <ProjectToolbar
        crumbs={crumbs}
        title={detailData.name}
        badgeTitle={detailData.type}
        badgeVariant='soft'
        badgeColor='default'
      />

      <TabContextCustom
        tabs={isExternal ? EXTERNAL_TABS : TABS}
        defaultTabValue='Dashboard'
        handleTabOnChange={handleTabOnChange}
        tabsSX={{
          ml: 3,
          pl: 0,
        }}
        tabPanels={tabPanels}
        currentTabValue={currentTab}
      />
    </Stack>
  );
};
