import _ from 'lodash';

import {
  ChipColorsType,
  ChipVariantsType,
  CompanyType,
  DebtInvestmentType,
  DeptNameType,
  DocumentListBadgeType,
  InvestorStatusListType,
  MarketplaceProjectActivityStatusType,
  MarketplaceStatusType,
  PartnerDashboardStatusList,
  PartnerDashboardTableStatusList,
  PartnerTransactionStatusType,
  PhaseType,
  ProjectPreparationStatusType,
  ProjectsOfferingStatusType,
  ProjectType,
  SellStatusListType,
  SourceType,
  StageType,
  StatusType,
  VisibilityListType,
} from '../../../types';
import { CheckboxOptionType } from '../components';

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16,
};

export const MAX_FILE_SIZE_MB = 100;

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};

export const INVESTOR_PORTAL = {
  MAX_WIDTH: 1152,
  HEADER_H: 64,
};

export const DEFAULT_CURRENCY = 'kr';
export const DEFAULT_AREA = 'm²';
export const UNSPECIFIED_VALUE = 'Unspecified';
export const NOT_USED = 'Not used';
export const EMPTY_VALUE = '-';

export const closeIcon = 'solar:close-circle-bold';
export const checkboxIcon = 'eva:checkmark-circle-2-fill';

export type ChipKeyVariants =
  SourceType
  | StatusType
  | StageType
  | ProjectType
  | MarketplaceStatusType
  | ProjectsOfferingStatusType
  | DebtInvestmentType
  | PhaseType
  | DocumentListBadgeType
  | SellStatusListType
  | DeptNameType
  | ProjectPreparationStatusType
  | VisibilityListType
  | PartnerTransactionStatusType
  | MarketplaceProjectActivityStatusType
  | InvestorStatusListType
  | PartnerDashboardStatusList
  | PartnerDashboardTableStatusList

export const allChipColors: Record<ChipKeyVariants, ChipColorsType> = {
  All: 'success',
  'New build apartments': 'default',
  'New build houses': 'default',
  'Investment': 'default',
  'Conversion': 'default',
  'Permitted development': 'default',
  'New build houses and apartments': 'default',
  Senior: 'primary',
  Junior: 'secondary',
  Bridge: 'success',
  Other: 'warning',
  Manual: 'error',
  Scraping: 'success',
  'Owner Request': 'info',
  Lead: 'secondary',
  Approved: 'success',
  New: 'secondary',
  'TS In Progress': 'info',
  'In Progress': 'info',
  'TS Signed': 'success',
  'DFA In Progress': 'primary',
  'DFA Signed': 'success',
  Done: 'success',
  Unlisted: 'error',
  'Ongoing Offerings': 'info',
  'Capital Raised': 'success',
  Recalculate: 'warning',
  Researched: 'success',
  Pending: 'secondary',
  'Waiting for Review': 'secondary',
  Rejected: 'error',
  Requested: 'info',
  Signed: 'success',
  Uploaded: 'success',
  'Term Sheet Requested': 'info',
  'Documents Added': 'success',
  'Documents Requested': 'secondary',
  'Agreement Requested': 'info',
  'Not used': 'default',
  Used: 'success',
  'Sent': 'secondary',
  'Not published': 'default',
  'Not Published': 'default',
  Published: 'success',
  'Not Selling': 'default',
  'On Sale': 'primary',
  Sold: 'success',
  Archive: 'secondary',
  Reserved: 'secondary',
  'Commitment Signed': 'success',
  'Invoice Sent': 'info',
  'Secured Capital': 'primary',
  'Soft commitment ready for sign': 'success',
  'Invoice Received': 'info',
  'No Shared': 'default',
  Raised: 'success',
  'TS signed': 'success',
  'New request': 'secondary',
  'TS requested': 'info',
  'Offer sent': 'info',
  'Offer declined': 'error',
  // TODO change colors
  Shared: 'success',
  'On marketplace': 'success',
  Finalised: 'success',
  'No Partners': 'success',
  Debt: 'success',
  Equity: 'success',
  Preliminary: 'success',
  Packaged: 'success',
  'Term Sheet': 'success',
  'Sign Loan': 'success',
};

export const allChipVariants: Record<ChipKeyVariants, ChipVariantsType> = {
  Manual: 'soft',
  Scraping: 'soft',
  New: 'outlined',
  Recalculate: 'outlined',
  'Owner Request': 'soft',
  All: 'outlined',
  Researched: 'outlined',
  Pending: 'outlined',
  Approved: 'outlined',
  'In Progress': 'outlined',
  'Waiting for Review': 'outlined',
  'No Partners': 'outlined',
  'Published': 'outlined',
  Rejected: 'outlined',
  'New build apartments': 'soft',
  'New build houses': 'soft',
  'Investment': 'soft',
  'Conversion': 'soft',
  'Permitted development': 'soft',
  'New build houses and apartments': 'soft',
  Lead: 'outlined',
  Requested: 'outlined',
  Signed: 'outlined',
  Uploaded: 'outlined',
  'Not Published': 'soft',
  Shared: 'outlined',
  'On marketplace': 'outlined',
  Finalised: 'outlined',
  'Ongoing Offerings': 'outlined',
  'Capital Raised': 'outlined',
  'Done': 'outlined',
  Unlisted: 'outlined',
  Debt: 'soft',
  Equity: 'soft',
  Preliminary: 'soft',
  Packaged: 'soft',
  'Sign Loan': 'soft',
  'Term Sheet': 'soft',
  'Term Sheet Requested': 'outlined',
  'TS In Progress': 'outlined',
  'TS Signed': 'outlined',
  'DFA In Progress': 'outlined',
  'DFA Signed': 'outlined',
  'Documents Added': 'outlined',
  'Documents Requested': 'outlined',
  'Agreement Requested': 'outlined',
  'Not Selling': 'outlined',
  'On Sale': 'outlined',
  Sold: 'outlined',
  'Not used': 'outlined',
  'Used': 'outlined',
  'No Shared': 'outlined',
  Raised: 'outlined',
  'TS signed': 'outlined',
  'New request': 'outlined',
  'TS requested': 'outlined',
  'Offer sent': 'outlined',
  'Offer declined': 'outlined',
  //   TODO change variant
  Senior: 'outlined',
  Junior: 'outlined',
  Bridge: 'outlined',
  Other: 'outlined',
  'Not published': 'outlined',
  Sent: 'outlined',
  Archive: 'outlined',
  Reserved: 'outlined',
  'Commitment Signed': 'outlined',
  'Invoice Sent': 'outlined',
  'Secured Capital': 'outlined',
  'Soft commitment ready for sign': 'outlined',
  'Invoice Received': 'outlined',
};

export const monthsShortLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const sectionsList = ['Lead', 'Research', 'Financing', 'Asset', 'Tendering', 'Distribution', 'Sell'];
export const sellList = ['Not Selling', 'Sold', 'On Sale'];

export const acceptedFilesType = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png';

export const distributionCheckboxesList: CheckboxOptionType[] = [
  {
    label: 'Research',
    value: 'research',
  },
  {
    label: 'Project Preparation',
    value: 'projectPreparation',
  },
  {
    label: 'Tendering',
    value: 'tendering',
  },
  {
    label: 'Debt finance',
    value: 'debtFinancing',
  },
  {
    label: 'Equity finance',
    value: 'equityFinancing',
  },
  {
    label: 'Sell',
    value: 'sell',
  },
];

export const slicedDistributionCheckboxesList: CheckboxOptionType[] = _.slice(distributionCheckboxesList, 2, 6);

export const distributionCheckboxesListForLead = (reducedMenu: boolean): CheckboxOptionType[] => distributionCheckboxesList.map(item => {
  return {
    ...item,
    disabled: reducedMenu && ['tendering', 'equityFinancing'].includes(item.value),
  };
});

export const distributionCheckboxesListForAsset = (reducedMenu: boolean): CheckboxOptionType[] => distributionCheckboxesList.map(item => {
  return {
    ...item,
    disabled: ['research', 'projectPreparation'].includes(item.value) || (reducedMenu && ['tendering', 'equityFinancing'].includes(item.value)),
  };
});


export const companyTypeList: CompanyType[] = [
  'Developer',
  'Bank',
  'Supplier',
  'Investor',
  'Other',
];
